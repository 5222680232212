<template>
  <div class="box">
    <el-form :model="info" label-position="right" :rules="rules" ref="ruleForm" label-width="100px" class="ruleForm" size="small">
      <el-form-item label="关键词" prop="keywords">
        <el-input v-model="info.keywords" placeholder="请输入关键词"></el-input>
      </el-form-item>
      <el-form-item label="类型" prop="reply_type">
        <el-radio-group v-model="info.reply_type" size="small" @input="changeReplyType">
          <el-radio-button label="1" border>文本</el-radio-button>
          <el-radio-button label="3" border>图片</el-radio-button>
          <el-radio-button label="2" border>图文</el-radio-button>
<!--          <el-radio-button label="4" border>音频</el-radio-button>-->
<!--          <el-radio-button label="5" border>视频</el-radio-button>-->
<!--          <el-radio-button label="6" border>视频号</el-radio-button>-->
        </el-radio-group>
      </el-form-item>
      <el-form-item label="内容" v-if="info.reply_type==1" prop="content_text">
        <el-input type="textarea" rows="8" v-model="content.text" placeholder="请输入内容"></el-input>
      </el-form-item>
      <el-form-item label="图片" prop="logo" v-if="info.reply_type==2 || info.reply_type==3">
        <SelectImgs :selectNum="1" btnTitle="选择图片" :selectData="[content.img]" :selectFunc="picChoose" :idx="1"></SelectImgs>
      </el-form-item>
      <el-form-item label="标题" v-if="info.reply_type==2">
        <el-input type="textarea" v-model="content.title" placeholder="请输入标题"></el-input>
      </el-form-item>
      <el-form-item label="描述" v-if="info.reply_type==2">
        <el-input type="textarea" rows="4" v-model="content.desc" placeholder="请输入描述"></el-input>
      </el-form-item>
      <el-form-item label="详情" prop="content" v-if="info.reply_type==2">
        <wang-edit :txt="content.content" :callback="setText"></wang-edit>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>

import SelectImgs from '@/components/SelectImgs'
import WangEdit from "@/components/WangEdit";
  export default {
    data() {
      return {
        id: 0,
        info: {
          keywords: '',
          reply_type: ''
        },
        content: {
          text: '',
          img: '',
          title: '',
          desc: '',
          content: ''
        },
        source_domain: this.config.SOURCE_DOMAIN,
        rules: {
          keywords: [
            { required: true, message: '请输入关键词', trigger: 'blur' }
          ],
          reply_type: [
            { required: true, message: '请选择类型', trigger: 'blur' }
          ]
        },
      };
    },
    components: {
      SelectImgs,
      WangEdit
    },
    created() {
      if(this.$route.query.id) {
        this.id = this.$route.query.id
        this.getInfo()
      }
    },
    mounted() {
    },
    computed: {
    },
    methods: {
      changeReplyType(e) {
        console.log(e)
      },
      picChoose(data) {
        this.content.img = data.pic
      },
      setText(data) {
        this.content.content = data
      },
      getInfo() {
        var that = this
        this.$api.channel.weixinReplyInfo( {id: this.id},function (res) {
          if(res.errcode == 0) {
            that.info = res.data
            that.content = res.data.reply
          }
        })
      },
      submitForm(formName) {
        var that = this
        this.$refs[formName].validate((valid) => {
          if (valid) {
            var param = that.info
            param.reply = that.content
            if(param.reply_type==1 && param.reply.text=='') {
              that.fail('请输入内容')
              return false
            }
            if(param.reply_type==2 && param.reply.img=='') {
              that.fail('请上传图片')
              return false
            }
            this.$api.channel.weixinReplyEdit(param,function (res) {
              if(res.errcode == 0) {
                that.success(res.errmsg)
                that.reBack()
              } else {
                that.fail(res.errmsg)
              }
            })
          } else {
            return false;
          }
        });
      },
    }
  };
</script>
<style scoped>
  .box {
    background-color: #fff;
    padding: 20px;
    border-radius: 6px;
    min-height: 500px;
  }
  .ruleForm {
    width: 70%;
  }
</style>
